import React from 'react'

// Custom CSS import
import './ourTeams.css';

const OurTeams = () => {
    return (
        <>
            <div className="page-heading-container">
                <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Our Team Members</h2>
                <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem", height: "0.3rem" }} />
            </div>

            {/* <div className="teams-container "> */}
                <div className="our-teams-memeber-container container " >
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/team-1.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Teams</h5>
                                <p className="card-text">With a passionate team we work to make your smile better</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/dr-poonam.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Dr Poonam Saste</h5>
                                <p className="card-text">Degree: BDS <br />
                                    Exp: 10 yrs of work experience.</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/dr-amit.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Dr Amit Saste</h5>
                                <p className="card-text">Degree: BDS, MDS <br />
                                    Exp: 12 yrs of experience </p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/dr-nilangi.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Dr. Nilangi Bhopi</h5>
                                <p className="card-text">Consultant Dentist.</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/dr-hafsa.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Dr. Hafsa khan</h5>
                                <p className="card-text">Consultant Dentist.</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/anuj.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Mr. Anuj Kumar</h5>
                                <p className="card-text">Dental assistant.</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/rupali.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Ms Rupali Ahre</h5>
                                <p className="card-text">Receptionist.</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/manish.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Mr. Manish Sharma</h5>
                                <p className="card-text">Dental Assistant.</p>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="card" style={{ width: "18rem" }}>
                            <img src="./images/team-images/nehal.png" className="card-img-top" alt="loading..." />
                            <div className="card-body">
                                <h5 className="card-title">Ms Nehal Bhalerao</h5>
                                <p className="card-text">Receptionist.</p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}

        </>
    )
}

export default OurTeams;