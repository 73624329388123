import React from 'react';
import { Link } from 'react-router-dom';
import SocialMedia from '../social-media/SocialMedia';

// Custom CSS import
import './footer.css';

const Footer = () => {
  return (
    <>
      {/* Remove the container if you want to extend the Footer to full width. */}
      <div className=" mt-5">
        <footer className="text-white text-center text-lg-start bg-dark">
          {/* Grid container */}
          <div className="container p-4">
            {/*Grid row*/}
            <div className="row mt-4">
              {/*Grid column*/}
              <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase mb-4">About company</h5>

                <p>
                An ISO 9001:2015 certified DENTAL PULSE Clinic was established in 2007 by Dr. Amit V. Sashte with a mission of spreading healthy beautiful smiles by integrating professionalism and expertise with art and design.
                </p>

                <div className="mt-4">
                  {/* <a to='https://www.facebook.com/DrAmitsdentalpulseclinic/' className="btn btn-floating btn-light btn-sm"><i className="fab fa-facebook-f"></i></a>
                  <a to='https://www.instagram.com/dentalpulseclinic/' className="btn btn-floating btn-light btn-sm"><i className="fab fa-instagram"></i></a>
                  <a to='instagram.com/dentalpulseclinic/' className="btn btn-floating btn-light btn-lg"><i className="fab fa-twitter"></i></a>
                  <a to='instagram.com/dentalpulseclinic/' className="btn btn-floating btn-light btn-lg"><i className="fab fa-google-plus-g"></i></a> */}
                  <SocialMedia />
                </div>
              </div>
              {/*Grid column*/}

              {/*Grid column*/}
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase mb-4 pb-1">QUICK LINKS</h5>
                <ul className="fa-ul" style={{ marginLeft: "1.65em" }}>
                  <li className="mb-3">
                    <span className="fa-li"><i className="fa fa-angle-right"></i></span><span className="ms-2"><Link style={{textDecoration:"none"}} to="/dental-implant">Best Dental Implant Center</Link></span>
                  </li>
                  <li className="mb-3">
                    <span className="fa-li"><i className="fa fa-angle-right"></i></span><span className="ms-2"><Link style={{textDecoration:"none"}} to="/root-canal-treatment">Best Root Canal Treatments</Link></span>
                  </li>
                  <li className="mb-3">
                    <span className="fa-li"><i className="fa fa-angle-right"></i></span><span className="ms-2"><Link style={{textDecoration:"none"}} to="/child-dentistry">Best Child Dental Care</Link></span>
                  </li>
                  <li className="mb-3">
                    <span className="fa-li"><i className="fa fa-angle-right"></i></span><span className="ms-2"><Link style={{textDecoration:"none"}} to="/smile-design">Best Smile Designing Center</Link></span>
                  </li>
                </ul>
              </div>
              {/*Grid column*/}

              {/*Grid column*/}
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase mb-4">Opening hours</h5>

                <table className="table text-center text-white">
                  <tbody className="fw-normal">
                    <tr>
                      <td>Mon - Sun:</td>
                      <td>10am - 9pm</td>
                    </tr>
                    <tr>
                      <td>Nerul:</td>
                      <td>+ 91 83692 40730</td>
                    </tr>
                    <tr>
                      <td>Seawoods:</td>
                      <td>+ 91 90824 68679</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/*Grid column*/}
            </div>
            {/*Grid row*/}
          </div>
          {/* Grid container */}

          {/* Copyright */}
          <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
            © 2022 Copyright: Dental Pulse Clinic Powered by <span>&nbsp;</span>
            <a  style={{textDecoration:"none"}} className="text-danger" href="https://yodiso.com/">yodiso</a>
          </div>
          {/* Copyright */}
        </footer>

      </div>
      {/* End of .container */}
    </>
  )
}

export default Footer;