import React from 'react';

// Custom CSS import
import './homeContact.css'

const HomeContact = () => {
    return (
        <>
            <div className="home-contact-section">
                <div className="section-heading">
                    <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>CONTACT US</h2>
                    <hr className='our-service-hr ' style={{ opacity: "1", color: "#99cc33", maxWidth: "13px", height: "13px" }} />
                </div>
                <div className="locations">
                    <h1 className="page-heading text-center mt-5">Dental Pulse Clinic, Seawoods</h1>
                    <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem", height:"0.3rem" }} />

                    <div className="container location-information mt-5">

                        {/*Kolar Road Google map  */}
                        <div className="google-location">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15087.990011683627!2d73.0162949!3d19.0198317!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf4ffa5ed57332024!2sDental%20Pulse!5e0!3m2!1sen!2sin!4v1655717509073!5m2!1sen!2sin" allowFullScreen="" loading="lazy" className='google-map container' title='google-map-milano'>
                            </iframe>
                        </div>

                        {/* business location google map link */}
                        <div className="address p-3 ">
                            <h2 className="text-center">ADDRESS</h2>
                            <div className="complete-address text-white" >
                                {/* <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6> */}
                                <p><i className="fas fa-home mr-3"></i> Shop No.17 &amp; 18, Sadguru Plaza, Sec 40, Plot No. 50, Near Seawoods Grand Central &amp; Seawoods railway Station , Seawoods, Nerul West, Navi Mumbai – 400706</p>
                                <p><i className="fas fa-envelope mr-3"></i> contact@dentalpulseclinic.com</p>
                                <p><i className="fas fa-phone mr-3"></i> + 91 90824 68679</p>
                                <p><i className="fas fa-stopwatch mr-3"></i> 10:00AM to 09:30PM</p>
                            </div>
                        </div>
                    </div>

                    {/* Houshangabad branch address and information */}
                    <h1 className="page-heading text-center mt-5">Dental Pulse Clinic, Nerul East</h1>
                    <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem", height:"0.3rem" }} />

                    <div className="container location-information mt-5">
                        {/*Business location google map link  */}
                        <div className="google-location">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15086.814419489447!2d73.0195958!3d19.032778!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5ae99b51c2d5444!2sDental%20Pulse!5e0!3m2!1sen!2sin!4v1655717753185!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='google-map container' title='google-map-hosangabad'></iframe>
                        </div>

                        {/* business location address */}
                        <div className="address p-3 ">
                            <h2 className="text-center">ADDRESS</h2>
                            <div className="complete-address text-white">
                                {/* <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6> */}
                                <p><i className="fas fa-home mr-3"></i> Shop No.3, Shiv Parvati Shopping Complex, Near MTNL,
                                    Opp Nerul Railway Station, Sector-21, Nerul  East, Navi Mumbai – 400706</p>
                                <p><i className="fas fa-envelope mr-3"></i> contact@dentalpulseclinic.com</p>
                                <p><i className="fas fa-phone mr-3"></i> + 91 83692 40730</p>
                                <p><i className="fas fa-stopwatch mr-3"></i> 10:00AM to 09:30PM</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeContact;