import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

// custom compnent import
import TopNavbar from './core/navbar/top-navbar/TopNavbar';
import MainNavbar from './core/navbar/main-navbar/MainNavbar';
import MainHomePage from './core/home-page/MainHomePage';
import AboutUs from './core/about-us/AboutUs';
import CosmeticDentistry from './core/cosmetic-dentistry/CosmeticDentistry';
import TeethWhitening from './core/teeth-whitening/TeethWhitening';
import SmileDesigning from './core/smile-designing/SmileDesigning';
import ToothExtraction from './core/tooth-extraction/ToothExtraction';
import Orthodontics from './core/orthodontics/Orthodontics';
import RootCanalTreatment from './core/root-canal-treatment/RootCanalTreatment';
import DentalImplant from './core/dental-implant/DentalImplant';
import ChildDentistry from './core/child-dentistry/ChildDentistry';
import Contact from './core/contact/Contact';
import PatientTestimonial from './core/patient-testimonial/PatientTestimonial';
import Footer from './core/footer/Footer';
import OurTeams from './core/our-teams/OurTeams';


const ProjectRoutes = () => {
    return (
        <>
            <Router >
                <TopNavbar />
                <MainNavbar />
                <Routes >
                    <Route exact path="/" element={<MainHomePage />} />
                    <Route exact path="/about" element={<AboutUs />} />
                    <Route exact path="/cosmetric-dentistry" element={<CosmeticDentistry />} />
                    <Route exact path="/teeth-whitening" element={<TeethWhitening />} />
                    <Route exact path="/smile-design" element={<SmileDesigning />} />
                    <Route exact path="/tooth-extraction" element={<ToothExtraction />} />
                    <Route exact path="/orthodontics" element={<Orthodontics />} />
                    <Route exact path="/root-canal-treatment" element={<RootCanalTreatment />} />
                    <Route exact path="/dental-implant" element={<DentalImplant />} />
                    <Route exact path="/child-dentistry" element={<ChildDentistry />} />
                    <Route exact path="/contact" element={<Contact />} />
                    <Route exact path="/testimonials" element={<PatientTestimonial />} />
                    <Route exact path="/our-teams" element={<OurTeams />} />
                </Routes>
                <Footer />
            </Router>
        </>
    )
}

export default ProjectRoutes;