import React from 'react';

// Custom CSS import
import './toothExtraction.css';

const ToothExtraction = () => {
  return (
    <>
      <div className="page-heading-container">
        <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Wisdom Tooth Removal/Extraction</h2>
        <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "250px", marginBottom: "3rem", height: "0.3rem" }} />
      </div>
      <div className="tooth-extraction-container container">
        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>What are Wisdom Teeth?</h4>
        <p>What are Wisdom Teeth? Wisdom teeth are the last molars on each side of the jaws. They are also the last teeth to emerge, or erupt, usually when a person is between 16 and 20.Since wisdom teeth are the last permanent teeth to come in, or erupt, there is often not enough room left in your mouth to accommodate them. This can lead to wisdom teeth that are impacted, teeth that are trapped beneath the gum tissue by other teeth or bone. If teeth are impacted, swelling and tenderness may occur.Wisdom teeth that only partially emerge or come in crooked can also lead to painful crowding and disease. Since teeth removed before age 20 have less developed roots and fewer complications, the American Dental Association recommends that people between 16 and 19 have their wisdom teeth evaluated to see if they need to be removed.</p>
        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>How are Wisdom Teeth Removed?</h4>
        <p>A tooth extraction is a relatively routine procedure. Your dentist or a dental specialist, called an oral surgeon, will recommend either “going to sleep” using general anesthesia, or numbing this area in your mouth with a local anesthesia such as Novocain®.After the tooth (or teeth) is removed, you may be asked to bite down softly on a piece of gauze for 30 to 45 minutes after you leave the office, to limit any bleeding that may occur. Some pain and swelling may occur but it will normally go away after a few days; however, you should call your dentist if you have prolonged or severe pain, swelling, bleeding or fever.Removal of wisdom teeth due to crowding or impaction should not affect your bite or oral health in the future.   HorizontalImpaction AngularImpaction VerticalImpaction</p>
        <div className="description-images">
          <img src="/images/services/tooth-extraction/horizontal-impaction.jpg" alt="loading..." />
          <img src="/images/services/tooth-extraction/angular-impaction.jpg" alt="loading..." />
          <img src="/images/services/tooth-extraction/vertical-impaction.jpg" alt="loading..." />
        </div>
        <p>However, when an impacted wisdom tooth tries to erupt, the overlying gum may swell and cause pain, which may also be felt in nearby teeth or the ear on that side. A partially erupted tooth can collect food and other debris, which can lead to gum swelling and an infection called pericoronitis. If untreated, this infection can spread toward the throat or into the neck. Impacted teeth also can cause problems if they develop decay or if they push on the neighboring molar, which could lead to tooth movement or decay, as well as changes in the bite (the way the teeth come together).</p>
        <h4 className="page-sub-heading" style={{ fontWeight: "bold", color: "rgb(10, 134, 202)" }}>Symptoms</h4>
        <p>Symptoms includes:</p>
        <ul>
          <li>Swelling of the gum in the back of the mouth or on the side of the jaw.</li>
          <li>Difficulty opening the jaw.</li>
          <li>Bad breath.</li>
          <li>An unpleasant taste in the mouth.</li>
          <li>Pain or irritation when you open your mouth.</li>
          <li>Pain when chewing or bitingPain can occur for several days and then disappear for weeks or months before returning.</li>
        </ul>
        <h4 className="page-sub-heading" style={{ fontWeight: "bold", color: "rgb(10, 134, 202)" }}>Treatment</h4>
        <p>You can sometimes relieve minor irritation by rinsing with warm salt water (1/2 teaspoon of salt in 8 ounces of water) or by taking over-the-counter painkillers.If the tooth continues to cause pain, is infected or is interfering with nearby teeth, the usual treatment is for the tooth to be extracted. This can sometimes be done in your dentist’s office with a local anesthetic, depending on how far the tooth is under the gum. Extracting one tooth can take 5 to 30 minutes, depending on the position of the tooth.People often are referred to an oral and maxillofacial surgeon to have an impacted tooth removed. It is not uncommon to have all the wisdom teeth removed to prevent problems in the future. This usually is done in the surgeon’s office with sedation and local anesthesia.Before the extraction, your dentist or oral surgeon will talk to you about the procedure and what type of anesthesia and sedatives he or she will use. You will not be able to eat for six hours before surgery and should take all your medications on schedule. Someone should drive you to the appointment and take you home.After the surgery, your cheeks may swell, and you will find it difficult to eat some foods. Follow your dentist’s or oral surgeon’s instructions carefully to minimize problems.Complications of surgery are rare but do occur.If the impacted tooth is not causing irritation or affecting nearby teeth, you won’t need immediate treatment. However, your dentist likely will recommend that the tooth be extracted to avoid problems later.</p>

        {/* youtube video emabde */}
        <div className="iframe-video-container text-center">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/-J3kztwBLyA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </>
  )
}

export default ToothExtraction;