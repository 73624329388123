import React from 'react';

// Custom CSS import
import './mainHomePage.css';

// Custom Components import
import HomeCarousel from './home-carousel/HomeCarousel';
import HomeAbout from './home-about/HomeAbout';
import HomeOurServices from './home-our-services/HomeOurServices';
import Counter from './counter/Counter';
import OurDentist from './our-dentist/OurDentist';
import PatientReviews from './patient-reviews/PatientReviews';
import HomeContact from './home-contact-page/HomeContact';

const MainHomePage = () => {
  return (
    <>
      {/* Home Carousel section import */}
      <HomeCarousel />

      {/* Home About us section */}
      <HomeAbout />

      {/* Home our services section */}
      <HomeOurServices />

      {/* Home Counter section */}
      <Counter />

      {/* Home Our-doctors section */}
    <OurDentist />

    {/* home patient reviews section */}
    <PatientReviews />

    {/* home Contact Us section */}
    <HomeContact />
    </>
  )
}

export default MainHomePage;