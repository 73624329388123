import React from 'react';

// Custom CSS import
import './smileDesigning.css';

const SmileDesigning = () => {
  return (
    <>
      <div className="page-heading-container">
        <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Smile Design</h2>
        <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem", height: "0.3rem" }} />
      </div>
      <div className="smile-designing-container container">
        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Creating a New Smile with Porcelain Veneers</h4>
        <p>In dentistry, a veneer is a wafer-thin layer of super-strong porcelain that convincingly substitutes for natural tooth enamel. When bonded to your teeth, veneers can create a natural-looking, beautiful new surface. That’s because dental porcelain, like natural tooth enamel, is translucent and tough.</p>
        <p>Veneers are custom-made, tooth colored shells that are bonded to your teeth with dental cement or bonding adhesive. Veneers cover the front surfaces of your teeth and can help improve your appearance and smile by covering up a tooth that is discolored, misshaped, or worn down. Veneers can also close gaps between teeth or repair a chipped tooth.</p>
        <h4 className="page-sub-heading" style={{ color: "rgb(10, 134, 202)", fontWeight: "bold" }}>Types Of Veneers</h4>
        <p>Veneers can be made from several different types of materials. Generally your dentist will help you decide which type is best for you.</p>
        <ul type="1">
          <li >Porcelain veneers</li>
          <li>Lumineers</li>
          <li>Composite veneers</li>
        </ul>
        <p>Porcelain veneers are the most common type of veneers. Porcelain has a very shiny surface that closely resembles your enamel and will give you a more natural and translucent appearance. In addition, porcelain is stain resistant and it is also very friendly to your gums.</p>
        <h4 className="page-sub-heading" style={{ color: "rgb(10, 134, 202)", fontWeight: "bold" }}>Veneer Treatment</h4>
        <p>It normally takes two visits for your dentist to complete your veneers. If preparation is necessary, on the first visit your dentist will use a dental hand piece to shape and prepare the teeth so the veneers will properly fit over them. Next, an impression of your teeth is taken and temporary veneers may be placed to protect the prepared teeth. The impression of your teeth is then sent to a dental laboratory where custom-fit veneers are made. During this first visit your dentist will also help you choose the proper shade for the color of your veneers.</p>
        <p>In most cases, on your second visit the veneers are placed and, if necessary, adjusted to create a proper fit. If the fit, shape and color of the veneers are satisfactory, your dentist will proceed with permanently cementing them into place.</p>
        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Caring for Your Veneers</h4>
        <p>Just like the teeth nature gave you, teeth restored with veneers need gentle brushing and flossing every day. This will remove dental plaque and ensure good gum tissue health around the veneers. Regular checkups at the dental office will remain as important as always to your oral and general health. Porcelain Veneers are usually not repairable should they chip or crack so you should attempt to avoid biting directly on small hard items such as hard candies, your fingernails, or pens. And you should never chew on anything cold and hard such as ice.</p>
        <p>If you grind your teeth during the day or at night, your dentist may recommend a night guard to protect your veneers from these grinding forces. A night guard is a plastic cover that fits over your teeth to deflect hard forces. Night guard will also increase the longevity of your veneers.</p>

        {/* youtube video emabde */}
        <div className="iframe-video-container text-center">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/NdQunaRzeGY?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
      </div>
    </>
  )
}

export default SmileDesigning;