import React from 'react'
import {Link} from "react-router-dom"

// Custom CSS import
import './homeOurServices.css';

const HomeOurServices = () => {
    return (
        <>
            <div className="our-services-container">
                <div className="section-heading">
                    <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>OUR SERVICES</h2>
                    <hr className='our-service-hr ' style={{ opacity: "1", color: "#99cc33", maxWidth: "13px", height: "13px" }} />
                </div>

                <div className="service-type-container container">
                    <div className="left-side-container">
                        <Link to="/root-canal-treatment" style={{textDecoration:"none"}}>
                            <div className="service-box">
                                <h2 className="service-name">Root Canal Treatment</h2>
                                <p>I'm a paragraph. Click here to add your own text and edit me. It's easy.</p>
                            </div>
                        </Link>
                        <Link to="/child-dentistry" style={{textDecoration:"none"}}>
                            <div className="service-box">
                                <h2 className="service-name">Child Dentistry</h2>
                                <p>I'm a paragraph. Click here to add your own text and edit me. It's easy.</p>
                            </div>
                        </Link>
                        <Link to="/denture" style={{textDecoration:"none"}}>
                            <div className="service-box">
                                <h2 className="service-name">Denture</h2>
                                <p>I'm a paragraph. Click here to add your own text and edit me. It's easy.</p>
                            </div>
                        </Link>
                    </div>
                    <div className="left-right-container">
                        <Link to="/orthodontic" style={{textDecoration:"none"}}>
                            <div className="service-box">
                                <h2 className="service-name">Orthodontics</h2>
                                <p>I'm a paragraph. Click here to add your own text and edit me. It's easy.</p>
                            </div>
                        </Link>
                        <Link to="smile-designing" style={{textDecoration:"none"}}>
                            <div className="service-box">
                                <h2 className="service-name">Smile Designing</h2>
                                <p>I'm a paragraph. Click here to add your own text and edit me. It's easy.</p>
                            </div>
                        </Link>
                        <Link to="teeth-whitening" style={{textDecoration:"none"}}>
                            <div className="service-box">
                                <h2 className="service-name">Teeth Whitening</h2>
                                <p>I'm a paragraph. Click here to add your own text and edit me. It's easy.</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeOurServices;