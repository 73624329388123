import React from 'react';
import {Link} from 'react-router-dom';

// Custom CSS import
import './topNavbar.css';

const TopNavbar = () => {
    return (
        <>
            <div className="top-container container-fluid ">
                <div className="items-container container">
                    <Link to="/">
                        <img src="./images/logo/logo-circular.png" style={{ height: '100px', width: 'auto' }} alt="loading..." />
                    </Link>

                    <h2 style={{ color: "#116089", fontWeight: "bold", fontSize: "34px", letterSpacing: "0.7rem" }}>DENTAL PULSE CLINIC</h2>
                    <div className="right-side-contact d-flex align-items-center">
                        <img style={{ maxWidth: "30px" }} src="./images/icons/phone-call.png" alt="" />
                        <h2 style={{ fontWeight: "bold", marginLeft: "10px" }}> 9082468679</h2>
                    </div>
                    <div className="socialmedia-icons">
                        {/* <SocialMedia /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopNavbar;