import React from 'react';

// Custom CSS import
import './dentalImplant.css';

const DentalImplant = () => {
  return (
    <>
      <div className="page-heading-container">
        <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Dental Implant</h2>
        <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem", height: "0.3rem" }} />
      </div>

      <div className="dental-implant-page-container container">
        <div className="image-contents-container">
          <div className="left-side-contents">
            <p>Of all the ways modern dentistry has to replace missing teeth, dental implants are by far the best. There is no tooth-replacement option that will give you a longer-lasting result. Implants also help preserve tooth-supporting bone that naturally deteriorates when a tooth is lost. Loss of bone is one of the major hidden consequences of losing teeth.</p>
            <p>A dental implant most often takes the form of a small, screw-shaped titanium post that replaces the root-part of a missing tooth. The surgical procedure used to place an implant is actually quite minor and routine, requiring only local anesthesia in most cases. After a healing period, the implant is topped with a lifelike crown custom-made to match your existing natural teeth. Implants have a documented success rate of over 95%, which is significantly higher than any other tooth-replacement option.</p>
          </div>
          <div className="right-side-image">
            <img className='implant-subpage-images' style={{ width: "100%" }} src="./images/services/dental-implant/1-dental-implant-anatomy-large.jpg" alt="loading..." />
          </div>
        </div>

        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>How Implants Works</h4>
        <p>During a minor surgical procedure, your dental implant is inserted directly into the jawbone in the space vacated by the missing tooth. It will then be left to heal for a period of months before the final crown is attached. During this healing period, the implant actually fuses to the bone surrounding it.</p>

        <h4 className="page-sub-heading" style={{ color: "rgb(10, 134, 202)", fontWeight: "bold" }}>Tooth Replacement Options Using Dental Implants</h4>
        <h6 className="page-sub-heading" style={{ fontWeight: "bold" }}>Implants can replace missing teeth in a variety of ways. They can be used to:</h6>

        <div className="image-contents-container">
          <div className="left-side-contents">
            <p>  <b>Replace One Tooth —</b>  When you have one tooth missing, a single implant is inserted into the bone to replace the root part of that tooth; a crown then goes on top to simulate an actual tooth. This treatment choice has the highest success rate, making it the best long-term investment for replacing a single missing tooth. Even if the initial cost is slightly higher than other options, it is the most cost-effective solution over time. An implant will never decay or need root canal treatment, and feels just like the tooth that was there.</p>
          </div>
          <div className="right-side-image">
            <img className='implant-subpage-images' src="./images/services/dental-implant/2-dental-implant.jpg" alt="loading..." />
          </div>
        </div>

        <div className="image-contents-container">
          <div className="left-side-contents">
            <p> <b>Replace Multiple Teeth —</b>  When you have more than one tooth missing, implants provide an ideal replacement mechanism. You don’t even need one implant for every missing tooth. Instead, implant teeth can act as supports for fixed bridgework. For example, if you are missing three teeth in a row, we can place two implants, one on either side of the gap, and a crown in between that has no implant underneath. That way, you won’t need to use any of your remaining natural teeth as bridge supports, which could weaken them and make them more susceptible to decay.</p>
          </div>
          <div className="right-side-image">
            <img className='implant-subpage-images' src="./images/services/dental-implant/3-dental-implant.jpg" alt="loading..." />
          </div>
        </div>

        <div className="image-contents-container">
          <div className="left-side-contents">
            <p> <b>Replace All Teeth Permanently-</b>  Implants can support an entire arch of upper or lower replacement teeth that are fixed into the mouth and are never removed. Sometimes the new teeth can be supported by as few as 4 implants. It’s comparable to the structure of a table, which only needs 4 legs to hold it up. In cases where jawbone density and volume have deteriorated, 5 or 6 implants might be needed to support a row of 10 to 12 teeth. Dental implant replacement teeth protect your jawbone, won’t slip, and should last a lifetime.</p>
          </div>
          <div className="right-side-image">
            <img className='implant-subpage-images' src="./images/services/dental-implant/4-dental-implant.jpg" alt="loading..." />
          </div>
        </div>

        <div className="image-contents-container">
          <div className="left-side-contents">
            <p> <b>Support Removable Dentures —</b>  Implants can even make removable dentures more comfortable, effective and healthier to wear. Traditional dentures rest on the gums and put pressure on the underlying bone. This accelerates bone loss so that the jaw shrinks and the dentures slip, particularly on the bottom. But today dentists can attach a removable denture onto implants, transferring that pressure into the bone structure rather than the bone surface. This prevents the dentures from slipping while you eat and speak, and preserves the bone directly beneath them.</p>
          </div>
          <div className="right-side-image">
            <img className='implant-subpage-images' src="./images/services/dental-implant/5-dental-implant.jpg" alt="loading..." />
          </div>
        </div>

        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Implant Care and Maintenance</h4>
        <p>There are only two ways an implant can lose attachment to the bone and fail once it has successfully fused: poor oral hygiene or excessive biting forces. Poor oral hygiene and/or a lack of regular cleanings can lead to a destructive bacterial infection called peri-implantitis. Flossing and brushing your teeth on a daily basis, along with regular professional cleanings, can prevent this. Excessive biting forces can come from either a habit of clenching or grinding your teeth, or an insufficient number of implants to handle the forces generated by your bite. You should receive the correct number of implants so this does not happen. And if you have a habit of grinding or clenching your teeth, a nightguard will be recommended to protect your implants. After all, implants are a long-term investment in your smile, your health and your well-being, so it’s best to protect your investment.</p>
      </div>
    </>
  )
}

export default DentalImplant;