import React from 'react';

// Custom CSS import
import './rootCanalTreatment.css';

const RootCanalTreatment = () => {
  return (
    <>
      <div className="page-heading-container">
        <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Root Canal Treatment/Endodontics</h2>
        <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "250px", marginBottom: "3rem", height: "0.3rem" }} />
      </div>

      <div className="root-canal-treatment-container container">
        <div className="image-contents-container">
          <div className="left-side-contents">
            <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>What is endodontic treatment?</h4>
            <p>“Endo” is the Greek word for “inside” and “odont” is Greek for “tooth.” Endodontic treatment treats the inside of the tooth. Root canal treatment is one type of endodontic treatment.</p>
            <p>To understand endodontic treatment, it helps to know something about the anatomy of the tooth. Inside the tooth, under the white enamel and a hard layer called the dentin, is a soft tissue called the pulp. The pulp contains blood vessels, nerves, and connective tissue and creates the surrounding hard tissues of the tooth during development.</p>
            <p>The pulp extends from the crown of the tooth to the tip of the roots where it connects to the tissues surrounding the root. The pulp is important during a tooth’s growth and development. However, once a tooth is fully mature it can survive without the pulp, because the tooth continues to be nourished by the tissues surrounding it.</p>
          </div>
          <div className="right-side-image">
            <img className='rct-subpage-images' src="./images/services/rct/root-canal-treatment.jpg" alt="loading..." />
          </div>
        </div>
        <br />

        <div className="image-contents-container">
          <div className="left-side-contents">
            <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Why would I need an endodontic procedure?</h4>
            <p>Why would I need an endodontic procedure?
              Endodontic treatment is necessary when the pulp, the soft tissue inside the root canal, becomes inflamed or infected. The inflammation or infection can have a variety of causes: deep decay, repeated dental procedures on the tooth, or a crack or chip in the tooth. In addition, an injury to a tooth may cause pulp damage even if the tooth has no visible chips or cracks. If pulp inflammation or infection is left untreated, it can cause pain or lead to an abscess</p>
          </div>
          <div className="right-side-image">
            <img className='rct-subpage-images' style={{ maxHeight: "265px" }} src="./images/services/rct/rct-procedure.jpg" alt="loading..." />
          </div>
        </div>
        <br />

        <div className="image-contents-container">
          <div className="left-side-contents">
            <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Step-by-Step Endodontic Procedure</h4>
            <p>Endodontic treatment can often be performed in one or two visits and involves the following steps:</p>
            <ul type="1">
              <li>The endodontist examines and x-rays the tooth, then administers local anesthetic. After the tooth is numb, the endodontist places a small protective sheet called a “dental dam” over the area to isolate the tooth and keep it clean and free of saliva during the procedure.</li>
              <li>The endodontist makes an opening in the crown of the tooth. Very small instruments are used to clean the pulp from the pulp chamber and root canals and to shape the space for filling.</li>
              <li>After the space is cleaned and shaped, the endodontist fills the root canals with a biocompatible material, usually a rubber-like material called gutta-percha. The gutta-percha is placed with an adhesive cement to ensure complete sealing of the root canals. In most cases, a temporary filling is placed to close the opening. The temporary filling will be removed by your dentist before the tooth is restored.</li>
              <li>After the final visit with your endodontist, you must return to your dentist to have a crown or other restoration placed on the tooth to protect and restore it to full function.</li>
            </ul>
          </div>
          <div className="right-side-image">
            <img className='rct-subpage-images' style={{ maxHeight: "200px", marginBottom: "1rem" }} src="./images/services/rct/rct-1.jpg" alt="loading..." />
            <img className='rct-subpage-images' style={{ maxHeight: "200px" }} src="./images/services/rct/rct-2.jpg" alt="loading..." />
          </div>
        </div>

        <p>If the tooth lacks sufficient structure to hold the restoration in place, your dentist or endodontist may place a post inside the tooth. Ask your dentist or endodontist for more details about the specific restoration planned for your tooth.</p>
        <div className="description-images">
          <img src="./images/services/rct/root-canal.jpg" alt="loading..." />
          <img src="./images/services/rct/root-canal-3.jpg" alt="loading..." />
          <img src="./images/services/rct/rct-1.jpg" alt="loading..." />
        </div>

        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>How much will the procedure cost?</h4>
        <p>The cost varies depending on how complex the problem is and which tooth is affected. Molars are more difficult to treat; the fee is usually more. Most dental insurance policies provide some coverage for endodontic treatment.</p>
        <p>Generally, endodontic treatment and restoration of the natural tooth are less expensive than the alternative of having the tooth extracted. An extracted tooth must be replaced with an implant or bridge to restore chewing function and prevent adjacent teeth from shifting. These procedures tend to cost more than endodontic treatment and appropriate restoration.</p>

        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Will the tooth need any special care or additional treatment after endodontic treatment?</h4>
        <p>You should not chew or bite on the treated tooth until you have had it restored by your dentist. The unrestored tooth is susceptible to fracture, so you should see your dentist for a full restoration as soon as possible. Otherwise, you need only practice good oral hygiene, including brushing, flossing, and regular checkups and cleanings.</p>
        <p>Most endodontically treated teeth last as long as other natural teeth. In a few cases, a tooth that has undergone endodontic treatment does not heal or the pain continues. Occasionally, the tooth may become painful or diseased months or even years after successful treatment. Often when this occurs, redoing the endodontic procedure can save the tooth.</p>

        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>What causes an endodontically treated tooth to need additional treatment?</h4>
        <p>New trauma, deep decay, or a loose, cracked or broken filling can cause new infection in your tooth. In some cases, the endodontist may discover additional very narrow or curved canals that could not be treated during the initial procedure.</p>

        <h4 className="page-sub-heading" style={{ fontWeight: "bold" }}>Can all teeth be treated endodontically?</h4>
        <p>Most teeth can be treated. Occasionally, a tooth can’t be saved because the root canals are not accessible, the root is severely fractured, the tooth doesn’t have adequate bone support, or the tooth cannot be restored. However, advances in endodontics are making it possible to save teeth that even a few years ago would have been lost. When endodontic treatment is not effective, endodontic surgery may be able to save the tooth.</p>
      </div>
    </>
  )
}

export default RootCanalTreatment;