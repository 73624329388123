import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom CSS import
import "./patientReviews.css";

const PatientReviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="review-parent-container">
        <div className="container-fluid review-container">
          <div className="section-heading">
            <h2
              className="text-center "
              style={{
                fontWeight: "bold",
                fontSize: "35px",
                color: "rgb(17, 96, 137)",
              }}
            >
              PATIENTS FEEDBACK
            </h2>
            <hr
              className="our-service-hr "
              style={{
                opacity: "1",
                color: "#99cc33",
                maxWidth: "13px",
                height: "13px",
              }}
            />
          </div>
          <Slider {...settings} className="reviews-contents">
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  I am very happy with my treatment by Dr Amit v sashte .Got my
                  root canal treatment, teeth cleaning, teeth filling and smile
                  make over treatment done here. Very satisfied with the
                  treatment. Highly recommended. From my numerous visits to The
                  Dentist, I found her (the Doctor) very forthcoming.sir always
                  give an honest opinion and sound advice. Not only that I is
                  pleased with the efficiency, friendliness and quick expert
                  execution, I also recommend to anyone to visit dental pulse Dr
                  Amit sashte sir desiring care of Dental problems. staffs also
                  very supporting nature . excellent job done. Review posted on
                  Google, 3 years
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Rakesh Kumar
                </div>
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Dental Pulse Clinic team is incredibly professional, friendly,
                  and accommodating. From the moment you walk in, you’re greeted
                  warmly by the reception staff. They make you feel at ease
                  during appointments, which is especially appreciated for
                  someone like me who’s a bit nervous about dental work. The
                  quality of care here is incredible and I always leave feeling
                  confident in my dental health. Thank you to the entire team at
                  Dental Pulse Clinic for providing such exceptional service!
                  Dr. Amit’s skills are truly magical! His expertise and gentle
                  touch make every dental visit a breeze. I couldn’t be happier
                  with the care I receive from him. Highly recommend!
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Aisha Khan
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Fantastic experience for me at the clinic for my Root Canal
                  treatment. Very efficient and effective work done by Dr. Amit.
                  Very happy with the treatment and results. Thanks to everyone
                  involved again.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Ketan Hulawale
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Dr. Pallavi and her staff are excellent. They are professional
                  and they are take the time to explain everything to the
                  patients..this has to be best place ever I feel like I have
                  known everyone there for years and theymake you feel
                  comfortable .. Dr. Pallavi is a great Dr...☺️
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Janhavi Bandagale
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  I had a fantastic experience at Dental Pulse. The staff was
                  incredibly friendly and professional. The clinic is clean and
                  well-maintained. Dr. Amit was thorough in explaining the
                  procedure and made me feel comfortable throughout. I highly
                  recommend Dental Pulse for anyone in need of dental care.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Vijay Sawant
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  I had a wonderful experience at Dental pulse. Dr. Amit and Dr.
                  Poonam are expert dentists and my dental problems were treated
                  with lot of care and expertise. They use the latest treatment
                  technique. Very friendly doctors. I felt comfortable and at
                  ease while undergoing treatment by Dr. Amit and Dr Poonam.
                  Thank you Dr. Amit and Dr Poonam Saste.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Arpita Palchoudhury
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  I want to thank Dr. Dentist Amit and his Dr. Dentist Poonam
                  for taking such good care of me during my recent dental
                  treatment. Dr. Amit was really professional and kind, and I
                  liked that he used methods that weren't too invasive. I
                  believe these methods will be good for me in the long run. I'm
                  really grateful to both of them for making sure I had a
                  positive and comfortable experience during my visit.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Mohammed Zayed
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Dr. Poonam Sashte and his staff are very cooperative and very
                  knowledgeable in their field. Dr. Amit Sashte is one of the
                  best root canal specialist in Navi Mumbai. Proud to be having
                  such great dentist from my native i.e. Alibag.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Shailesh Pednekar
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Very excellent service. Dr Amit had done d root canals
                  ..painless n in single visit .. Dr. Poonam fixed d teeth
                  crowns . All done efficiently ..👍👌👍👌
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Pandrang Kambli
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  They do great work, the way of talking is great, the behavior
                  of all the doctors is great. Thanku So Muchh All Of Uh ❤️✨🤞
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Ankita Singh
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Quite happy with my RC treatment and right and continuous
                  advice of Dr. Amit Sashte post treatment. I would strongly
                  recommend him for any dental tratment as I am committed to do
                  so for my rest of the teeth’s.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Sunil Deshpande
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Excellent doctor ! I visited this clinic for pain in my lower
                  teeths, Dr. Amit Sashte listened to all my problems very
                  carefully and calmly explained me the best treatment. Also
                  ,the entire treatment was pain-free and completed within a
                  short period of time. In addition , the clinic staff is also
                  very friendly and helpful here.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---priyanka madane
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Best ever dental clinic for everyone with advanced technology.{" "}
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---prajakta mokal
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Very professional and expert doctors. They give right advice
                  and have the latest equipment{" "}
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Prashant Sarangi
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Excellent and very efficient and cordial team of doctors{" "}
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Meera Keskar
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/female.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Very quick , reliable, affordable and trustworthy treatment
                  and root canal single settings treatment,nice{" "}
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Roopali Kapade
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Simply brilliant... 😊{" "}
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Anurag Mahajan
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Cost effective, good service, nice doctor, flexibility of time
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Sajjad Saeed
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  One place solution for root canal treatment &amp; smile
                  designing. Done root canals in single sitting &amp; front
                  teeth replacement with crowns totally changed my
                  smile.Excellant work done by Dr Amit &amp; Dr Poonam.Also
                  thanks for giving appointments according to my time.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---Devendra Kalan
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
            <div>
              <div className="tour-desc bg-white">
                <div className="d-flex justify-content-center pt-2 pb-2 image-frame">
                  <img
                    className="tm-people"
                    src="./images/patient-images/male.png"
                    alt="loading..."
                  />
                </div>
                <div className="tour-text color-grey-3 text-center">
                  Doctor are ok, but they charge very high even more than any
                  branded hospital. If you have large money in home then go to
                  this clinic. Else if you have very less money then go to
                  branded hospital like Apollo hospital.
                </div>
                <div className="link-name d-flex justify-content-center">
                  ---vikrant thakur
                </div>
                {/* <div className="link-position d-flex justify-content-center">Student</div> */}
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default PatientReviews;
