import React from 'react';
import { Link } from 'react-router-dom';

// Custom CSS import
import './childDentistry.css';

const ChildDentistry = () => {
  return (
    <>
        <div className="page-heading-container">
        <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Child Dentistry</h2>
        <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem", height: "0.3rem" }} />
      </div>

      <h2 className="text-center">This Page Contents will Comming Soon !</h2>
      <Link to="/home" className='d-flex justify-content-center'>
        <button type="submit" className="btn btn-primary mb-3">Go Back to Home</button>
      </Link>
    </>
  )
}

export default ChildDentistry;