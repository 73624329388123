import React from 'react';
import PatientReviews from '../home-page/patient-reviews/PatientReviews';

// Custom CSS import
import './patinetTestimonial.css';

const PatientTestimonial = () => {
  return (
    <>
      {/* patient google reviews */}
      <PatientReviews />

      <div className="page-heading-container">
        <h2 className="text-center mt-3 " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>Patient Videos Feedback</h2>
        <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "250px", marginBottom: "3rem", height: "0.3rem" }} />
      </div>

      {/* youtube videos reviews */}
      <div className="iframe-video-container text-center container">
        <div className="patient-videos">
          <iframe width="360" height="315" src="https://www.youtube.com/embed/4mu9ZVryZEU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>

        <div className="patinet-videos">
          <iframe width="360" height="315" src="https://www.youtube.com/embed/kMv1DZEiHXc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>

        <div className="patient-videos">
          <iframe width="360" height="315" src="https://www.youtube.com/embed/7LmbaqYTIMg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
      </div>
    </>
  )
}

export default PatientTestimonial;