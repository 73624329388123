import React from 'react';
import {Link} from 'react-router-dom'

// Custom CSS import
import './ourDentist.css';

const OurDentist = () => {
    return (
        <>
            <div className="our-dentist-container">
                <div className="section-heading">
                    <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>OUR DENTISTS</h2>
                    <hr className='our-service-hr ' style={{ opacity: "1", color: "#99cc33", maxWidth: "13px", height: "13px" }} />
                </div>
                <div className="our-dentist-section-contents container">
                    <div className="left-side-doctor-contents text-center">
                        <img className='doc-images' src="/images/doc-images/dr-amit.png" alt="loading..." />
                        <h2 className="doc-name">Dr. Amit Sashte</h2>
                        <p className="doc-title">micro Endodontist &amp; Aesthetic Dentist</p>
                    </div>
                    <div className="right-side-doctor-contents text-center">
                        <img className='doc-images' src="/images/doc-images/dr-poonam.png" alt="loading..." />
                        <h2 className="doc-name">Dr. Poonam Sashte</h2>
                        <p className="doc-title">Dental Surgeon &amp; Implantalogist</p>
                    </div>
                </div>
                <div className="view-more-doc-button text-center mt-3">
                    <Link to="/our-teams"><button type="button" className="btn btn-outline-primary btn-lg">All Team Members</button></Link>
                </div>
            </div>
        </>
    )
}

export default OurDentist;