import React from 'react';

// Custom CSS import
import './homeAbout.css';

const HomeAbout = () => {
    return (
        <>
            <div className="home-about-container container">
                <div className="section-heading">
                    <h2 className="text-center " style={{ fontWeight: "bold", fontSize: "35px", color: "rgb(17, 96, 137)" }}>ABOUT US</h2>
                    <hr className='our-service-hr ' style={{ opacity: "1", color: "#99cc33", maxWidth: "13px", height: "13px" }} />
                </div>

                <div className="home-about-section-contents">
                    <h6 className="sub-heading" style={{ fontWeight: "bold" }} >An ISO 9001:2015 certified DENTAL PULSE Clinic was established in 2007 by Dr. Amit V. Sashte with a mission of spreading healthy beautiful smiles by integrating professionalism and expertise with art and design.</h6>
                    <p>We provide comprehensive dental care, incorporating the most hygienic, modern and safe practices.</p>
                    <p>We want to help restore or enhance your most valuable asset…. Your Smile!</p>
                    <p>Discover quality, excellence and comfortable individualized care at our practice.</p>
                    <p>After exploring various treatment options at our online dental practice, please feel free to give us a call and schedule a consultation to further discuss your dental concerns and needs with our doctors.</p>
                    <h6 className="sub-heading" style={{ fontWeight: "bold" }} >We never settle for second-best, offering nothing but excellence for our patients.</h6>
                    <h6 className="sub-heading" style={{ fontWeight: "bold" }} >We did not invent dentistry, but we are redefining the dental experience.</h6>
                    <p>Since its inception the clinic has been instrumental in changing the fundamental concepts of basic high quality dental care. The clinic remains open from 10:00 am to 2:00 pm, and 5:00 pm to 9:30 pm all days excluding Sundays. The clinic extends a range of treatment facilities from general dentistry to specialization under one roof, ensuring quality dental care at affordable costs. The clinic caters to your needs with state of art dental equipments.  The clinic has kept abreast with all the latest advancements in esthetic and cosmetic dentistry.</p>
                    <p>The vision of Dental Pulse is to create a positive image in the minds of patients towards oral health care and at the same time to assist enhancing practice standards by infusing highest degree of professional discipline amongst empanelled dentists’ approach. We would only promote ethical dental practice by encouraging our dentists to use standard treatment materials and state-of-the-art technology along with skilled services and raise the bar of our treatment to world class level.</p>
                    <h6 className="sub-heading" style={{ fontWeight: "bold" }} >We use the best because we believe that our patients deserve the best.</h6>
                </div>
            </div>
        </>
    )
}

export default HomeAbout;