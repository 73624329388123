import React from 'react';

// Custom CSS import
import './counter.css';

const Counter = () => {
    return (
        <>
            <div className="counter-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter">
                                <div className="counter-icon">
                                    {/* <i className="fa fa-chart-line"></i> */}
                                    <i className="fa fa-first-aid"></i>
                                </div>
                                <span className="counter-value">13+</span>
                                <h3>Years of Experience</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter purple">
                                <div className="counter-icon">
                                    <i className="fa fa-user-shield"></i>
                                </div>
                                <span className="counter-value">10+</span>
                                <h3>Happy Staffs</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter blue">
                                <div className="counter-icon">
                                    <i className="fa fa-graduation-cap"></i>
                                </div>
                                <span className="counter-value">26+</span>
                                <h3>Master Certifications</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="counter green">
                                <div className="counter-icon">
                                    <i className="fa fa-users"></i>
                                </div>
                                <span className="counter-value">3000+</span>
                                <h3>Smiling Clients</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Counter;