import React from 'react';

// Custom CSS import
import './socialMedia.css';

const SocialMedia = () => {
  return (
    <>
      <div className="social-media-container">
        <div className='d-flex gap-1 social-media-icons-container'>
          <a href="https://www.facebook.com/DrAmitsdentalpulseclinic" target='_blank' rel="noopener noreferrer"><img src="./images/social-media-icons/facebook.png" className="d-block social-media-icon " alt="loading..." /></a>
          <a href="https://www.instagram.com/dentalpulseclinic" target='_blank' rel="noopener noreferrer"><img src="./images/social-media-icons/instagram.png" className="d-block social-media-icon" alt="loading..." /></a>
          <a href="https://www.linkedin.com/" target='_blank' rel="noopener noreferrer"><img src="./images/social-media-icons/linkedin.png" className="d-block social-media-icon" alt="loading..." /></a>
          {/* <a href="https://twitter.com/" target='_blank' rel="noopener noreferrer"><img src="./images/social-media-icons/twitter.png" className="d-block social-media-icon" alt="loading..." /></a> */}
        </div>
      </div>
    </>
  )
}

export default SocialMedia;