import React from 'react' ;

// Custom CSS import
import './contact.css';

// Custom Componenets import
import HomeContact from '../home-page/home-contact-page/HomeContact';
import ContactForm from '../contact-form/ContactForm';

const Contact = () => {

  return (
    <>
      {/* import home contact section */}
      <HomeContact />

      {/* import contact form */}
      <ContactForm />
    </>
  )
}

export default Contact;