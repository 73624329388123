import React from 'react';

// Custom CSS import
import './aboutUs.css';

// Custome componenets import
import HomeAbout from '../home-page/home-about/HomeAbout'
import HomeOurServices from '../home-page/home-our-services/HomeOurServices'
import OurDentist from '../home-page/our-dentist/OurDentist'
import Counter from '../home-page/counter/Counter'
import PatientReviews from '../home-page/patient-reviews/PatientReviews'

const AboutUs = () => {
    return (
        <>
            {/* Home about us section render */}
            <HomeAbout />

            {/* home our services */}
            <HomeOurServices />

            {/* home our doctors section */}
            <OurDentist />

            {/* home counter */}
            <Counter />

            {/* home patients feedback */}
            <PatientReviews />
        </>
    )
}

export default AboutUs;