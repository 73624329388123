import React from 'react';

// Custom CSS import
import './homeCarousel.css';

const HomeCarousel = () => {
  return (
    <>
        <div className="home-page-sliders">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./images/home-page/home-sliders/slide-1.png" className="d-block w-100 home-sliders-image" alt="loading..." />
                            {/* <div className="carousel-caption d-none d-sm-block">
                                <h2>Kiwi Consulting Group</h2>
                                <p>Your Healthcare Partner.</p>
                            </div> */}
                        </div>
                        <div className="carousel-item">
                            <img src="./images/home-page/home-sliders/slide-2.png" className="d-block w-100 home-sliders-image" alt="loading..." />
                            {/* <div className="carousel-caption d-none d-sm-block">
                                <h2>Kiwi Consulting Group</h2>
                                <p>Your Healthcare Partner.</p>
                            </div> */}
                        </div>
                        <div className="carousel-item">
                            <img src="./images/home-page/home-sliders/slide-3.png" className="d-block w-100 home-sliders-image" alt="loading..." />
                            {/* <div className="carousel-caption d-none d-sm-block">
                                <h2>Kiwi Consulting Group</h2>
                                <p>Your Healthcare Partner.</p>
                            </div> */}
                        </div>
                        <div className="carousel-item">
                            <img src="./images/home-page/home-sliders/slide-4.png" className="d-block w-100 home-sliders-image" alt="loading..." />
                            {/* <div className="carousel-caption d-none d-sm-block">
                                <h2>Kiwi Consulting Group</h2>
                                <p>Your Healthcare Partner.</p>
                            </div> */}
                        </div>
                        <div className="carousel-item">
                            <img src="./images/home-page/home-sliders/slide-5.png" className="d-block w-100 home-sliders-image" alt="loading..." />
                            {/* <div className="carousel-caption d-none d-sm-block">
                                <h2>Kiwi Consulting Group</h2>
                                <p>Your Healthcare Partner.</p>
                            </div> */}
                        </div>
                        <div className="carousel-item">
                            <img src="./images/home-page/home-sliders/slide-6.png" className="d-block w-100 home-sliders-image" alt="loading..." />
                            {/* <div className="carousel-caption d-none d-sm-block">
                                <h2>Kiwi Consulting Group</h2>
                                <p>Your Healthcare Partner.</p>
                            </div> */}
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
    </>
  )
}

export default HomeCarousel;